const autoNgTemplateLoaderTemplate1 = require('./nomadLogViewer.html');

angular.module('portainer.nomad').component('nomadLogViewer', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'NomadLogViewerController',
  bindings: {
    stderrLog: '<',
    stdoutLog: '<',
    resourceName: '<',
    logCollectionChange: '<',
  },
});

const autoNgTemplateLoaderTemplate1 = require('./docker-compose-form.html');

import controller from './docker-compose-form.controller.js';

export const edgeStacksDockerComposeForm = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,

  bindings: {
    formValues: '=',
    state: '=',
    onChangeGitCredential: '<',
  },
};

const autoNgTemplateLoaderTemplate1 = require('./kube-manifest-form.html');

import controller from './kube-manifest-form.controller.js';

export const kubeManifestForm = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,

  bindings: {
    formValues: '=',
    state: '=',
    onChangeGitCredential: '<',
  },
};

const autoNgTemplateLoaderTemplate1 = require('./stacksDatatable.html');

angular.module('portainer.app').component('stacksDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'StacksDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
    removeAction: '<',
    offlineMode: '<',
    refreshCallback: '<',
    createEnabled: '<',
    enableImageNotification: '<',
  },
});
